@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .caucus-option {
        @apply text-xl text-center py-3 hover:shadow-md hover:bg-slate-300 hover:cursor-pointer;
    }

    .app {
        @apply mt-16 w-1/3 h-1/2 shadow-xl rounded-md bg-slate-200;
    }

    .timer-control {
        @apply bg-gray-300 px-2 py-1 rounded shadow-md hover:bg-gray-400 transition-all;
    }

}